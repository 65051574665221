<template>
  <div>
    <div style="text-align: right; margin-bottom: 10px">
      <span style="float: left; font-weight: bold">供货商发货日期折线图</span>
      <el-select
        style="margin-right: 20px"
        size="mini"
        clearable
        v-model="supplierId"
        @change="timerFilterTypeSelectChange"
      >
        <el-option
          v-for="item in supplierOptions"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>

      <el-select
        style="margin-right: 20px"
        size="mini"
        v-model="productId"
        clearable
        filterable
        @focus="onFocus"
        remote
        placeholder="请输入商品名称"
        :remote-method="userRemoteFun"
        @change="userIdSelectChange"
      >
        <el-option
          v-for="item in productOption"
          :key="item.id"
          :label="item.value"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <el-date-picker
        style="margin-right: 20px"
        size="mini"
        v-model="timer"
        type="datetimerange"
        align="right"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :default-time="['00:00:00', '23:59:59']"
        @change="mothRangeChange"
      >
      </el-date-picker>

      <el-button type="success" size="mini" @click="exportExcel"
        >导出Excel</el-button
      >
    </div>

    <div
      v-loading="loading"
      :id="id"
      :class="className"
      :style="{ height: height, width: width }"
    ></div>
    <div>总发货数量: {{ saleAllPrice }} 件</div>
  </div>
</template>

<script>
import { timeOffset } from '@/utils/zoneTime.js'
import * as echarts from 'echarts'
import resize from './mixins/resize'
import { getProductTimeSendNumber } from '@/api/procurement.js'
import { getFilterProduct } from '@/api/product.js'
import { getAll } from '@/api/supplier.js'
import { iosOnFocus } from '@/utils/iosPlatform.js'
export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart',
    },
    id: {
      type: String,
      default: 'chart',
    },
    height: {
      type: String,
      default: '200px',
    },
    width: {
      type: String,
      default: '200px',
    },
  },
  created() {},
  async mounted() {
    var now = new Date() //当前日期
    var nowMonth = now.getMonth() //当前月
    var nowYear = now.getFullYear() //当前年
    //本月的开始时间
    var timeStar = new Date(nowYear, nowMonth, 1)
    //本月的结束时间
    var timeEnd = new Date(nowYear, nowMonth + 1, 0)

    this.timer = [timeStar, timeEnd]
    this.getReport()

    this.getSupplierOptions()
  },
  data() {
    return {
      loading: false,
      chart: null,
      data: null,
      timer: '',
      productId: null,
      productOption: [],
      supplierId: null,
      supplierOptions: [],
    }
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    // 用户远程搜索
    async userRemoteFun(key) {
      if (key !== '') {
        let { data } = await getFilterProduct(key)
        this.productOption = data
      }
    },
    // 用户名选中更改
    async userIdSelectChange(dataId) {
      this.productId = dataId
      this.getReport()
    },
    timerFilterTypeSelectChange(val) {
      this.timeFilterType = val
      this.getReport()
    },
    initChart() {
      if (this.chart) {
        this.chart.dispose()
        this.chart = null
      }

      this.chart = echarts.init(document.getElementById(this.id))

      let legendData = this.data.map((item) => {
        return item[0].name
      })

      let dateArr = this.data.reduce((prev, curr) => {
        let arr = curr.reduce((pi, ci) => {
          return [...pi, new Date(ci.time)]
        }, [])

        return [...prev, ...arr]
      }, [])

      console.log(dateArr)

      let maxDate = new Date(Math.max.apply(null, dateArr))
      let minDate = new Date(Math.min.apply(null, dateArr))

      let dateArange = []
      let oneDay = 24 * 3600 * 1000

      for (let i = +minDate; i <= +maxDate; i += oneDay) {
        dateArange.push(new Date(i))
      }

      // console.log(dateArange)

      let seriesData = this.data.reduce((prev, curr) => {
        let data = []
        dateArange.forEach((element) => {
          if (
            !curr.some((i) => {
              return +new Date(i.time) == +element
            })
          ) {
            data.push(null)
          } else {
            data.push(
              curr.filter((i) => {
                return +new Date(i.time) == +element
              })[0].value
            )
          }
        })

        //console.log(data)

        return [
          ...prev,
          {
            emphasis: {
              focus: 'series',
            },
            name: curr[0].name,
            type: 'line',
            // stack: 'Total',
            connectNulls: true,
            data,
          },
        ]
      }, [])

      let keyData = {}
      this.data.forEach((item) => {
        item.forEach((i) => {
          keyData[i.time] = 0
        })
      })

      let xAxisData = dateArange.map((item) => {
        return this.$options.filters['timeFormat'](item, 'yyyy-MM-dd')
      })

      console.log(xAxisData)

      let option = {
        title: {
          text: '',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985',
            },
          },
        },
        legend: {
          data: legendData,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: xAxisData,
          },
        ],
        yAxis: [
          {
            type: 'value',
          },
        ],
        series: seriesData,
      }

      option && this.chart.setOption(option)
    },
    async getReport() {
      this.loading = true
      let timerFilter = {}

      if (this.productId) {
        timerFilter.productId = this.productId
      }

      if (this.supplierId) {
        timerFilter.supplierId = this.supplierId
      }

      if (this.timer) {
        timerFilter.startTime = timeOffset(this.timer[0])
        timerFilter.endTime = timeOffset(this.timer[1])
      }

      console.log(timerFilter)

      let { data } = await getProductTimeSendNumber(timerFilter)

      this.data = data

      this.initChart()
      this.loading = false
    },
    mothRangeChange(timer) {
      this.getReport()
    },
    exportExcel() {
      if (this.data && this.data.length > 0) {
        let dateArr = this.data.reduce((prev, curr) => {
          let arr = curr.reduce((pi, ci) => {
            return [...pi, new Date(ci.time)]
          }, [])

          return [...prev, ...arr]
        }, [])

        let maxDate = new Date(Math.max.apply(null, dateArr))
        let minDate = new Date(Math.min.apply(null, dateArr))

        let dateArange = []
        let oneDay = 24 * 3600 * 1000

        for (let i = +minDate; i <= +maxDate; i += oneDay) {
          dateArange.push(new Date(i))
        }

        let seriesData = this.data.reduce((prev, curr) => {
          let data = []
          dateArange.forEach((element) => {
            if (
              !curr.some((i) => {
                return +new Date(i.time) == +element
              })
            ) {
              data.push(null)
            } else {
              data.push(
                curr.filter((i) => {
                  return +new Date(i.time) == +element
                })[0].value
              )
            }
          })

          return [...prev, [curr[0].name, ...data, this.arrSum(data)]]
        }, [])

        let xAxisData = dateArange.map((item) => {
          return this.$options.filters['timeFormat'](item, 'yyyy-MM-dd')
        })

        xAxisData = ['', ...xAxisData, '总计']

        // 空白分割区域
        for (let i = 0; i < 10; i++) {
          seriesData.push(
            xAxisData.map((i) => {
              return null
            })
          )
        }

        seriesData.push(xAxisData)

        let orderString = this.data.reduce((prev, curr) => {
          let data = []
          dateArange.forEach((element) => {
            if (
              !curr.some((i) => {
                return +new Date(i.time) == +element
              })
            ) {
              data.push(null)
            } else {
              data.push(
                curr.filter((i) => {
                  return +new Date(i.time) == +element
                })[0].orderNoString
              )
            }
          })

          return [...prev, [curr[0].name, ...data, this.arrSum(data)]]
        }, [])

        seriesData = [...seriesData, ...orderString]

        let filename = '采购商发货表'

        if (this.timer) {
          filename +=
            '_' +
            this.$options.filters['timeFormat'](this.timer[0], 'yyyy-MM-dd') +
            '_' +
            this.$options.filters['timeFormat'](this.timer[1], 'yyyy-MM-dd')
        }

        import('@/vendor/Export2Excel').then((excel) => {
          excel.export_json_to_excel({
            header: xAxisData, //Header Required
            data: seriesData, //Specific data Required
            filename, //Optional
            autoWidth: true, //Optional
            bookType: 'xlsx', //Optional
          })
        })
      }
    },
    arrSum(arr) {
      let sum = 0
      arr.forEach((item) => {
        if (item != null) {
          sum += item
        }
      })
      return sum
    },
    async getSupplierOptions() {
      let { data } = await getAll()
      this.supplierOptions = data
    },
    onFocus(event) {
      iosOnFocus.call(this,event);
    },
  },
  computed: {
    saleAllPrice() {
      let sumPrice = 0
      if (!this.data) {
        return sumPrice
      }

      this.data.forEach((item) => {
        item.forEach((i) => {
          sumPrice += i.value
        })
      })

      return sumPrice.toFixed(0)
    },
  },
}
</script>

<style lang="scss" scoped></style>
