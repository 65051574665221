<template>
  <div>
    <el-row style="margin-bottom: 20px" :gutter="20">
      <el-col
        :xs="{ span: 24 }"
        :sm="{ span: 24 }"
        :md="{ span: 24 }"
        :lg="{ span: 24 }"
        :xl="{ span: 24 }"
        style="padding-right: 8px; margin-bottom: 30px"
      >
        <ProductSaleNumberPieChart
          class="echart_card"
          :id="'ProductSaleNumberPieChart'"
          height="500px"
          width="100%"
        />
      </el-col>

      <el-col
        :xs="{ span: 24 }"
        :sm="{ span: 24 }"
        :md="{ span: 24 }"
        :lg="{ span: 24 }"
        :xl="{ span: 24 }"
        style="padding-right: 8px; margin-bottom: 30px"
      >
        <ProductTimeSendLineChart
          class="echart_card"
          :id="'productTimeSendLineChart'"
          height="800px"
          width="100%"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import ProductSaleNumberPieChart from './charts/ProductSaleNumberPieChart.vue'
import ProductTimeSendLineChart from './charts/ProductTimeSendLineChart.vue'
export default {
  components: {
    ProductSaleNumberPieChart,
    ProductTimeSendLineChart,
  },
  data() {
    return {}
  },
  created() {
    this.getUserReport()
  },
  methods: {
    async getUserReport() {},
  },
}
</script>

<style lang="scss" scoped>
.echart_card {
  padding: 20px;
  box-sizing: border-box;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
</style>
