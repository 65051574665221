export function timeOffset(time) {
  if (time instanceof Date) {
    let numberTime = time.getTime()
    // getTimezoneOffset()获取当地时间与标准时间的时间差值，返回分钟数，
    //若为正当地时间先与标准时间，若为负当地时间后与标准时间
    // getTimezoneOffset()api地址：https://developer.mozilla.org/zh-CN/docs/Web/JavaScript/Reference/Global_Objects/Date/getTimezoneOffset
    let offectTime = time.getTimezoneOffset() * 60000
    if (offectTime > 0) {
      return new Date(numberTime + offectTime)
    } else if (offectTime < 0) {
      return new Date(numberTime - offectTime)
    } else {
      return time
    }
  }
  return {}
}
